import { useEffect, useState, useRef } from 'react';
import { Card, Modal, Spinner } from 'react-bootstrap';
import '../../../styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import EditEmployeeView from './EditEmployeeView';

const stateList = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];
const reportTypes = ["All Active Employees Report (PDF)", "Filtered Employees List (PDF)", "Employees Grouped By Division (PDF)",
    "All Active Employees Report (CSV)", "Filtered Employees List (CSV)", "Employees Grouped By Division (CSV)"];

// *** For testing ***
// const reportServiceURL = 'http://localhost:4001';
// const managerGql = 'http://localhost:4000/graphql';

// *** For deploy ***
const reportServiceURL = 'https://employee-report-node-service-bjudfyf5za-uc.a.run.app'
const managerGql = 'https://asset-employee-gql-bjudfyf5za-uc.a.run.app/graphql'

const EmployeeManagerInterface = () => {
    /* Consts & UseStates */
    const [selEmployeeInformation, setSelEmployeeInformation] = useState({
        personID: "",
        firstName: "",
        lastName: "",
        title: "",
        division: "",
        cellPhone: "",
        homePhone: "",
        officePhone: "",
        extension: "",
        status: "",
        statusString: "",
        physicalStreet: "",
        physicalCity: "",
        physicalState: "",
        physicalZip: "",
        mailingStreet: "",
        mailingCity: "",
        mailingState: "",
        mailingZip: "",
        photo: "",
        photoID: "",
        agronomistNumber: "",
        employeeType: "",
        email: ""
    });
    // Copy of unedited employee information to refill form in case of cancel
    const [selEmployeeInfoUnedited, setSelEmployeeInfoUnedited] = useState({
        personID: "",
        firstName: "",
        lastName: "",
        title: "",
        division: "",
        cellPhone: "",
        homePhone: "",
        officePhone: "",
        extension: "",
        status: "",
        statusString: "",
        physicalStreet: "",
        physicalCity: "",
        physicalState: "",
        physicalZip: "",
        mailingStreet: "",
        mailingCity: "",
        mailingState: "",
        mailingZip: "",
        photo: "",
        photoID: "",
        agronomistNumber: "",
        employeeType: "",
        email: ""
    })
    const [filters, setFilters] = useState({
        radioStatus: "1",
        division: "",
        searchString: ""
    })
    // Copy of unedited filters to apply in case of cancel
    const [filtersUnedited, setFiltersUnedited] = useState({
        radioStatus: "1",
        division: "",
        searchString: ""
    })
    // const [editingMode, setEditingMode] = useState(false);
    const [creatingNewEmployee, setCreatingNewEmployee] = useState(false);
    const [selCardIndex, setSelCardIndex] = useState("");
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [filterCombine, setFilterCombine] = useState("");
    const [pendingChanges, setPendingChanges] = useState(false);
    const [selReportType, setSelReportType] = useState('');

    const [pdf, setPdf] = useState('');
    const [loadingPdfPreview, setLoadingPdfPreview] = useState(false);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [divisionList, setDivisionList] = useState([]);
    const [employeeTypeList, setEmployeeTypeList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [loadingEmployeeSave, setLoadingEmployeeSave] = useState(false);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const fileInput = useRef()
    const [removePhoto, setRemovePhoto] = useState(false);
    const [newImageB64, setNewImageB64] = useState(false);
    const [employeeExists, setEmployeeExists] = useState(false);

    let s = [];

    // For Save Changes modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { if (pendingChanges) { setShow(true) } };

    /* Handlers */
    // Handler to update filters in filter modal
    const handleUpdateFiltersReports = (e) => {
        e.preventDefault();
        const value = e.target.value;
        switch (e.target.id) {
            case "filterDivision":
                setFilters(prevState => {
                    return { ...prevState, division: value }
                });
                break;
            case 'report-type':
                setSelReportType(value)
                break;
            case "filter-search-bar":
                setFilters(prevState => {
                    return { ...prevState, searchString: value }
                })
                break;
            default:
                break;
        }
    }

    async function handleRefreshData(personID) {
        setNewImageB64(false)
        setSelCardIndex(personID)
        const getPersonInfoQuery = `
            {
                getPersonInfo(
                    People_ID: ${JSON.stringify(personID)}
                ) {
                    FirstName_VC
                    LastName_VC
                    PhysicalAddress_VC 
                    PhysicalCity_VC
                    PhysicalState_VC
                    PhysicalZip_VC
                    MailingAddress_VC
                    MailingCity_VC
                    MailingState_VC
                    MailingZip_VC
                    CellPhone_VC
                    HomePhone_VC
                    OfficePhone_VC
                    OfficeExtension_VC
                    EmployeeType_ID
                    Active_BT
                    AgronomistNumber_IN
                    Email_VC
                    Division_ID
                    Division_VC
                    JobTitle_VC
                    IsManager_BT
                    ImagePath_VC
                    ImageActive_BT
                    PeopleImage_ID
                }
            }
            `;
        const getPersonInfoFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getPersonInfoQuery }), headers: { 'Content-Type': 'application/json' } });
        const getPersonInfoJson = await getPersonInfoFetch.json();
        const employeeInfo = getPersonInfoJson.data.getPersonInfo[0]

        if (employeeInfo.ImageActive_BT === 0 || employeeInfo.ImageActive_BT === "0" || employeeInfo.ImageActive_BT === false || employeeInfo.ImageActive_BT === "false") {
            employeeInfo.ImagePath_VC = ""
            employeeInfo.PeopleImage_ID = ""
        }
        if (employeeInfo.Active_BT === true || employeeInfo.Active_BT === "true") {
            employeeInfo.Active_BT = "1"
            employeeInfo.statusString = "Active"
        } else if (employeeInfo.Active_BT === false || employeeInfo.Active_BT === "false") {
            employeeInfo.Active_BT = "0"
            employeeInfo.statusString = "Inactive"
        }

        setSelEmployeeInformation({
            personID: personID || "",
            firstName: employeeInfo.FirstName_VC || "",
            lastName: employeeInfo.LastName_VC || "",
            title: employeeInfo.JobTitle_VC || "",
            division: employeeInfo.Division_ID || "",
            cellPhone: employeeInfo.CellPhone_VC || "",
            homePhone: employeeInfo.HomePhone_VC || "",
            officePhone: employeeInfo.OfficePhone_VC || "",
            extension: employeeInfo.OfficeExtension_VC || "",
            status: employeeInfo.Active_BT || "",
            statusString: employeeInfo.statusString || "",
            physicalStreet: employeeInfo.PhysicalAddress_VC || "",
            physicalCity: employeeInfo.PhysicalCity_VC || "",
            physicalState: employeeInfo.PhysicalState_VC || "",
            physicalZip: employeeInfo.PhysicalZip_VC || "",
            mailingStreet: employeeInfo.MailingAddress_VC || "",
            mailingCity: employeeInfo.MailingCity_VC || "",
            mailingState: employeeInfo.MailingState_VC || "",
            mailingZip: employeeInfo.MailingZip_VC || "",
            photo: employeeInfo.ImagePath_VC || "",
            photoID: employeeInfo.PeopleImage_ID || "",
            agronomistNumber: employeeInfo.AgronomistNumber_IN || "",
            employeeType: employeeInfo.EmployeeType_ID || "",
            email: employeeInfo.Email_VC || ""
        });
        setSelEmployeeInfoUnedited({
            personID: personID || "",
            firstName: employeeInfo.FirstName_VC || "",
            lastName: employeeInfo.LastName_VC || "",
            title: employeeInfo.JobTitle_VC || "",
            division: employeeInfo.Division_ID || "",
            cellPhone: employeeInfo.CellPhone_VC || "",
            homePhone: employeeInfo.HomePhone_VC || "",
            officePhone: employeeInfo.OfficePhone_VC || "",
            extension: employeeInfo.OfficeExtension_VC || "",
            status: employeeInfo.Active_BT || "",
            statusString: employeeInfo.statusString || "",
            physicalStreet: employeeInfo.PhysicalAddress_VC || "",
            physicalCity: employeeInfo.PhysicalCity_VC || "",
            physicalState: employeeInfo.PhysicalState_VC || "",
            physicalZip: employeeInfo.PhysicalZip_VC || "",
            mailingStreet: employeeInfo.MailingAddress_VC || "",
            mailingCity: employeeInfo.MailingCity_VC || "",
            mailingState: employeeInfo.MailingState_VC || "",
            mailingZip: employeeInfo.MailingZip_VC || "",
            photo: employeeInfo.ImagePath_VC || "",
            photoID: employeeInfo.PeopleImage_ID || "",
            agronomistNumber: employeeInfo.AgronomistNumber_IN || "",
            employeeType: employeeInfo.EmployeeType_ID || "",
            email: employeeInfo.Email_VC || ""
        });

    }

    const handleCheckExistingEmployee = async (email) => {
        const getEmployeeQuery = `
        {
            getPersonByEmail(
                Email_VC: ${JSON.stringify(email)}
            ) {
                People_ID
            }
        }
        `;
        const getEmployeeFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getEmployeeQuery }), headers: { 'Content-Type': 'application/json' } });
        const getEmployeeJson = await getEmployeeFetch.json();
        let employeeID = 0
        if(getEmployeeJson && getEmployeeJson.data && getEmployeeJson.data.getPersonByEmail && getEmployeeJson.data.getPersonByEmail.People_ID ) {
            employeeID = getEmployeeJson.data.getPersonByEmail.People_ID
        }
        if (employeeID !== 0) {
            return true
        } else {
            return false
        }   
    }
    
    // Handler to update selectedEmployee
    const handleUpdateSelectedEmployee = async (personID) => {
        setNewImageB64(false)
        setLoadingCard(true)
        // Only update selected employee if no pending changes and not creating new employee
        if (!pendingChanges && !creatingNewEmployee) {
            setSelCardIndex(personID)
            const getPersonInfoQuery = `
            {
                getPersonInfo(
                    People_ID: ${JSON.stringify(personID)}
                ) {
                    FirstName_VC
                    LastName_VC
                    PhysicalAddress_VC 
                    PhysicalCity_VC
                    PhysicalState_VC
                    PhysicalZip_VC
                    MailingAddress_VC
                    MailingCity_VC
                    MailingState_VC
                    MailingZip_VC
                    CellPhone_VC
                    HomePhone_VC
                    OfficePhone_VC
                    OfficeExtension_VC
                    EmployeeType_ID
                    Active_BT
                    AgronomistNumber_IN
                    Email_VC
                    Division_ID
                    Division_VC
                    JobTitle_VC
                    IsManager_BT
                    ImagePath_VC
                    ImageActive_BT
                    PeopleImage_ID
                }
            }
            `;
            const getPersonInfoFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getPersonInfoQuery }), headers: { 'Content-Type': 'application/json' } });
            const getPersonInfoJson = await getPersonInfoFetch.json();
            const employeeInfo = getPersonInfoJson.data.getPersonInfo[0]
            if (employeeInfo.ImageActive_BT && (employeeInfo.ImageActive_BT === 0 || employeeInfo.ImageActive_BT === "0" || employeeInfo.ImageActive_BT === false || employeeInfo.ImageActive_BT === "false")) {
                employeeInfo.ImagePath_VC = ""
                employeeInfo.PeopleImage_ID = ""
            }
            if (employeeInfo.Active_BT === true || employeeInfo.Active_BT === "true") {
                employeeInfo.Active_BT = "1"
                employeeInfo.statusString = "Active"
            } else if (employeeInfo.Active_BT === false || employeeInfo.Active_BT === "false") {
                employeeInfo.Active_BT = "0"
                employeeInfo.statusString = "Inactive"
            }
            setSelEmployeeInformation({
                personID: personID || "",
                firstName: employeeInfo.FirstName_VC || "",
                lastName: employeeInfo.LastName_VC || "",
                title: employeeInfo.JobTitle_VC || "",
                division: employeeInfo.Division_ID || "",
                cellPhone: employeeInfo.CellPhone_VC || "",
                homePhone: employeeInfo.HomePhone_VC || "",
                officePhone: employeeInfo.OfficePhone_VC || "",
                extension: employeeInfo.OfficeExtension_VC || "",
                status: employeeInfo.Active_BT || "",
                statusString: employeeInfo.statusString || "",
                physicalStreet: employeeInfo.PhysicalAddress_VC || "",
                physicalCity: employeeInfo.PhysicalCity_VC || "",
                physicalState: employeeInfo.PhysicalState_VC || "",
                physicalZip: employeeInfo.PhysicalZip_VC || "",
                mailingStreet: employeeInfo.MailingAddress_VC || "",
                mailingCity: employeeInfo.MailingCity_VC || "",
                mailingState: employeeInfo.MailingState_VC || "",
                mailingZip: employeeInfo.MailingZip_VC || "",
                photo: employeeInfo.ImagePath_VC || "",
                photoID: employeeInfo.PeopleImage_ID || "",
                agronomistNumber: employeeInfo.AgronomistNumber_IN || "",
                employeeType: employeeInfo.EmployeeType_ID || "",
                email: employeeInfo.Email_VC || ""
            });
            setSelEmployeeInfoUnedited({
                personID: personID || "",
                firstName: employeeInfo.FirstName_VC || "",
                lastName: employeeInfo.LastName_VC || "",
                title: employeeInfo.JobTitle_VC || "",
                division: employeeInfo.Division_ID || "",
                cellPhone: employeeInfo.CellPhone_VC || "",
                homePhone: employeeInfo.HomePhone_VC || "",
                officePhone: employeeInfo.OfficePhone_VC || "",
                extension: employeeInfo.OfficeExtension_VC || "",
                status: employeeInfo.Active_BT || "",
                statusString: employeeInfo.statusString || "",
                physicalStreet: employeeInfo.PhysicalAddress_VC || "",
                physicalCity: employeeInfo.PhysicalCity_VC || "",
                physicalState: employeeInfo.PhysicalState_VC || "",
                physicalZip: employeeInfo.PhysicalZip_VC || "",
                mailingStreet: employeeInfo.MailingAddress_VC || "",
                mailingCity: employeeInfo.MailingCity_VC || "",
                mailingState: employeeInfo.MailingState_VC || "",
                mailingZip: employeeInfo.MailingZip_VC || "",
                photo: employeeInfo.ImagePath_VC || "",
                photoID: employeeInfo.PeopleImage_ID || "",
                agronomistNumber: employeeInfo.AgronomistNumber_IN || "",
                employeeType: employeeInfo.EmployeeType_ID || "",
                email: employeeInfo.Email_VC || ""
            });
            // If pending changes, and not creating a new employee, prompt to save changes to current employee
        } else if (pendingChanges && !creatingNewEmployee) {
            handleShow()
        }
        // If creating a new employee & pending changes, prompt to save a new employee
        else if (creatingNewEmployee && pendingChanges) {
            handleShow()
        }
        setLoadingCard(false)
    }
    // Handler to update info for selectedEmployee
    const handleUpdateSelEmployeeInfo = (e) => {
        if (!pendingChanges) {
            setPendingChanges(true)
        }
        e.preventDefault();
        const value = e.target.value;
        switch (e.target.id) {
            case "prefFirstName":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, firstName: value }
                });
                break;
            case "prefLastName":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, lastName: value }
                });
                break;
            case "prefTitle":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, title: value }
                });
                break;
            case "prefDivision":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, division: value }
                });
                break;
            case "prefHomePhone":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, homePhone: value }
                });
                break;
            case "prefCellPhone":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, cellPhone: value }
                });
                break;
            case "prefOfficePhone":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, officePhone: value }
                });
                break;
            case "prefExtension":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, extension: value }
                });
                break;
            case "prefPhyStreet":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, physicalStreet: value }
                });
                break;
            case "prefPhyCity":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, physicalCity: value }
                });
                break;
            case "prefPhyState":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, physicalState: value }
                });
                break;
            case "prefPhyZip":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, physicalZip: value }
                });
                break;
            case "prefMailStreet":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, mailingStreet: value }
                });
                break;
            case "prefMailCity":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, mailingCity: value }
                });
                break;
            case "prefMailState":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, mailingState: value }
                });
                break;
            case "prefMailZip":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, mailingZip: value }
                });
                break;
            case "prefAgroNumber":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, agronomistNumber: value }
                });
                break;
            case "prefEmpType":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, employeeType: value }
                });
                break;
            case "prefEmail":
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, email: value }
                });
                break;
            default:
                break;
        }
    }
    // Handler to set creating new employee mode
    const handleSetNewEmployeeMode = (wantToCreate) => {
        // Only want to create new if not currently creating another
        if (!creatingNewEmployee && wantToCreate && !pendingChanges) {
            // Clear any old information from other employees
            setSelCardIndex("")
            setSelEmployeeInformation({
                personID: "",
                firstName: "",
                lastName: "",
                title: "",
                division: "",
                cellPhone: "",
                homePhone: "",
                officePhone: "",
                extension: "",
                status: "",
                statusString: "",
                physicalStreet: "",
                physicalCity: "",
                physicalState: "",
                physicalZip: "",
                mailingStreet: "",
                mailingCity: "",
                mailingState: "",
                mailingZip: "",
                photo: "",
                photoID: "",
                agronomistNumber: "",
                employeeType: "",
                email: ""
            })
            setSelEmployeeInfoUnedited({
                personID: "",
                firstName: "",
                lastName: "",
                title: "",
                division: "",
                cellPhone: "",
                homePhone: "",
                officePhone: "",
                extension: "",
                status: "",
                statusString: "",
                physicalStreet: "",
                physicalCity: "",
                physicalState: "",
                physicalZip: "",
                mailingStreet: "",
                mailingCity: "",
                mailingState: "",
                mailingZip: "",
                photo: "",
                photoID: "",
                agronomistNumber: "",
                employeeType: "",
                email: ""
            })
            // Turn on creation mode
            setCreatingNewEmployee(true)
            // When creating is true, will set unedited to empty strings (default) will use when clearing
        } else if (!wantToCreate) {
            setCreatingNewEmployee(false)
        } else if (pendingChanges && wantToCreate) {
            handleShow()
        }
    }

    //Handler for radio buttons
    const handleEmployeeRadioInfo = (e) => {
        const value = e.target.value
        let aStatus = ''
        if (value === 1 || value === "1") {
            aStatus = "Active"
        } else if (value === 0 || value === "0") {
            aStatus = "Inactive"
        }

        setSelEmployeeInformation(prevState => {
            return {
                ...prevState,
                status: value,
                statusString: aStatus
            }
        })
        if (!pendingChanges) {
            setPendingChanges(true)
        }
    }
    // Handle Filter radio
    const handleFilterRadio = (e) => {
        const value = e.target.value;
        switch (e.target.id) {
            case "radioFilterStatus":
                setFilters(prevState => {
                    return { ...prevState, radioStatus: value }
                })
                break;
            default:
                break;
        }
    }
    //Handler for copying physical to mailing address and vice versa
    const handleCopyToAddress = (direction) => {
        setPendingChanges(true)
        if (direction === "toMailing") {
            // Copy physical address to mailing address
            setSelEmployeeInformation(prevState => {
                return {
                    ...prevState,
                    mailingStreet: selEmployeeInformation.physicalStreet,
                    mailingCity: selEmployeeInformation.physicalCity,
                    mailingState: selEmployeeInformation.physicalState,
                    mailingZip: selEmployeeInformation.physicalZip
                }
            });
        } else {
            // Copy mailing address to physical address
            setSelEmployeeInformation(prevState => {
                return {
                    ...prevState,
                    physicalStreet: selEmployeeInformation.mailingStreet,
                    physicalCity: selEmployeeInformation.mailingCity,
                    physicalState: selEmployeeInformation.mailingState,
                    physicalZip: selEmployeeInformation.mailingZip
                }
            });
        }
    }
    // Helper function for handleSearchKeyUp
    function getNestedStrings(obj, level) {
        if (obj) {
            if (level === 1) { s = [] }  // at parent level, start with an empty array
            const valArr = Object.values(obj);  // get array of object property values
            const strArr = valArr.filter((o) => { return (typeof o === 'string') });  // filter array for strings
            const objArr = valArr.filter((o) => { return (typeof o === 'object') });  // filter array for objects
            if (strArr.length > 0) { s = [...s, ...strArr]; };  // append strings to result array
            objArr.forEach((x) => { getNestedStrings(x, level + 1) });  // recurse through objects collecting child strings
            if (level === 1) { return s; };  // at parent level, return the string array
        }
    };
    // Handles search bar
    const handleSearchKeyUp = (event) => {
        event.preventDefault();
        const searchString = event.target.value.toLowerCase();
        if (searchString.length && !filters.division.length && !filters.radioStatus.length) {
            const res = (employeeList.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(searchString.toLowerCase()))
                }))
            }));
            setFilteredEmployees(res)  // show result object(s)
        }
        else if (searchString.length && (filters.division.length || filters.radioStatus.length)) {
            const res = (filterCombine.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(searchString.toLowerCase()))
                }))
            }));
            setFilteredEmployees(res)  // show result object(s)
        }
        else if (!searchString.length && (filters.division.length || filters.radioStatus.length)) {
            setFilteredEmployees(filterCombine)
        }
        else {
            setFilteredEmployees(employeeList)
        }
    }

    const handleReapplySearchString = (sort = []) => {
        let employees = []
        if (sort.length > 0) {
            employees = sort
        } else {
            employees = filteredEmployees
        }
        if (filters.searchString.length) {
            const res = (employees.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(filters.searchString.toLowerCase()))
                }))
            }));
            setFilteredEmployees(res)  // show result object(s)
        }
    }

    // Handle filters
    const handleApplyFilters = () => {
        setLoadingEmployees(true)
        getEmployees()

        setSelCardIndex("")
        setSelEmployeeInformation({
            personID: "",
            firstName: "",
            lastName: "",
            title: "",
            division: "",
            cellPhone: "",
            homePhone: "",
            officePhone: "",
            extension: "",
            status: "",
            statusString: "",
            physicalStreet: "",
            physicalCity: "",
            physicalState: "",
            physicalZip: "",
            mailingStreet: "",
            mailingCity: "",
            mailingState: "",
            mailingZip: "",
            photo: "",
            photoID: "",
            agronomistNumber: "",
            employeeType: "",
            email: ""
        });
        setSelEmployeeInfoUnedited({
            personID: "",
            firstName: "",
            lastName: "",
            title: "",
            division: "",
            cellPhone: "",
            homePhone: "",
            officePhone: "",
            extension: "",
            status: "",
            statusString: "",
            physicalStreet: "",
            physicalCity: "",
            physicalState: "",
            physicalZip: "",
            mailingStreet: "",
            mailingCity: "",
            mailingState: "",
            mailingZip: "",
            photo: "",
            photoID: "",
            agronomistNumber: "",
            employeeType: "",
            email: ""
        })
    }

    // Function to sort data alphabetically by employee name
    function sortEmployees() {
        const sortedFirst = filteredEmployees.sort((a, b) => {
            if (a.FirstName_VC < b.FirstName_VC) {
                return -1;
            } else if (a.FirstName_VC > b.FirstName_VC) {
                return 1;
            } else {
                return 0
            }
        });
        const sortedData = sortedFirst.sort((a, b) => {
            if (a.LastName_VC < b.LastName_VC) {
                return -1;
            } else if (a.LastName_VC > b.LastName_VC) {
                return 1;
            } else {
                return 0
            }
        });

        setFilteredEmployees(sortedData)
    }


    // Handle downloading reports
    async function handleDownloadReport() {
        if (selReportType.includes("PDF")) {
            setDownloadingPdf(true);
        }

        let endpoint = '';
        let data = '';
        let fileExtension = '';
        switch (selReportType) {
            case "All Active Employees Report (PDF)":
                endpoint = '/employeeListAtoZ'
                fileExtension = '.pdf'
                const activeEmployeesReportQuery = `
                    {
                        getActiveEmployeesForReport {
                                FirstName_VC
                                LastName_VC
                                JobTitle_VC
                                Division_ID
                                Division_VC
                                CellPhone_VC
                                HomePhone_VC
                                OfficePhone_VC
                                OfficeExtension_VC
                                MailingAddress_VC
                                MailingCity_VC
                                MailingState_VC
                                MailingZip_VC
                                Email_VC
                            }
                        }
                    `;

                const activeEmployeesReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: activeEmployeesReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const activeEmployeesReportJson = await activeEmployeesReportFetch.json();

                data = JSON.stringify({ "employees": activeEmployeesReportJson.data.getActiveEmployeesForReport })
                break;
            case "Filtered Employees List (PDF)":
                endpoint = '/employeeListAtoZ'
                fileExtension = '.pdf'
                let peopleIDList = []
                filteredEmployees.forEach((person) => {
                    peopleIDList.push(person.People_ID)
                })
                const filteredEmployeesReportQuery = `
                    {
                        getFilteredEmployeesForReport(
                            PeopleIDList: ${JSON.stringify(peopleIDList)}
                        ) {
                                FirstName_VC
                                LastName_VC
                                JobTitle_VC
                                Division_ID
                                Division_VC
                                CellPhone_VC
                                HomePhone_VC
                                OfficePhone_VC
                                OfficeExtension_VC
                                MailingAddress_VC
                                MailingCity_VC
                                MailingState_VC
                                MailingZip_VC
                                Email_VC
                            }
                        }
                    `;

                const filteredEmployeesReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: filteredEmployeesReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const filteredEmployeesReportJson = await filteredEmployeesReportFetch.json();

                data = JSON.stringify({ "employees": filteredEmployeesReportJson.data.getFilteredEmployeesForReport })
                break;
            case "Employees Grouped By Division (PDF)":
                endpoint = '/employeeListByDivision'
                fileExtension = '.pdf'
                const employeesByDivisionReportQuery = `
                {
                    getEmployeesByDivisionForReport {
                            FirstName_VC
                            LastName_VC
                            JobTitle_VC
                            Division_ID
                            Division_VC
                            CellPhone_VC
                            HomePhone_VC
                            OfficePhone_VC
                            OfficeExtension_VC
                            MailingAddress_VC
                            MailingCity_VC
                            MailingState_VC
                            MailingZip_VC
                            Email_VC
                        }
                    }
                `;

                const employeesByDivisionReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: employeesByDivisionReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const employeesByDivisionReportJson = await employeesByDivisionReportFetch.json();

                data = JSON.stringify({ "employees": employeesByDivisionReportJson.data.getEmployeesByDivisionForReport, "divisions": divisionList })
                break;
            case "All Active Employees Report (CSV)":
                endpoint = '/employeeListAtoZCSV'
                fileExtension = '.csv'
                const activeEmployeesReportCSVQuery = `
                        {
                            getActiveEmployeesForReport {
                                    FirstName_VC
                                    LastName_VC
                                    JobTitle_VC
                                    Division_ID
                                    Division_VC
                                    CellPhone_VC
                                    HomePhone_VC
                                    OfficePhone_VC
                                    OfficeExtension_VC
                                    MailingAddress_VC
                                    MailingCity_VC
                                    MailingState_VC
                                    MailingZip_VC
                                    Email_VC
                                }
                            }
                        `;

                const activeEmployeesReportCSVFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: activeEmployeesReportCSVQuery }), headers: { 'Content-Type': 'application/json' } });
                const activeEmployeesReportCSVJson = await activeEmployeesReportCSVFetch.json();

                data = JSON.stringify({ "employees": activeEmployeesReportCSVJson.data.getActiveEmployeesForReport })
                break;
            case "Filtered Employees List (CSV)":
                endpoint = '/employeeListAtoZCSV'
                fileExtension = '.csv'
                let peopleIDListCSV = []
                filteredEmployees.forEach((person) => {
                    peopleIDListCSV.push(person.People_ID)
                })
                const filteredEmployeesReportCSVQuery = `
                        {
                            getFilteredEmployeesForReport(
                                PeopleIDList: ${JSON.stringify(peopleIDListCSV)}
                            ) {
                                    FirstName_VC
                                    LastName_VC
                                    JobTitle_VC
                                    Division_ID
                                    Division_VC
                                    CellPhone_VC
                                    HomePhone_VC
                                    OfficePhone_VC
                                    OfficeExtension_VC
                                    MailingAddress_VC
                                    MailingCity_VC
                                    MailingState_VC
                                    MailingZip_VC
                                    Email_VC
                                }
                            }
                        `;

                const filteredEmployeesReportCSVFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: filteredEmployeesReportCSVQuery }), headers: { 'Content-Type': 'application/json' } });
                const filteredEmployeesReportCSVJson = await filteredEmployeesReportCSVFetch.json();

                data = JSON.stringify({ "employees": filteredEmployeesReportCSVJson.data.getFilteredEmployeesForReport })
                break;
            case "Employees Grouped By Division (CSV)":
                endpoint = '/employeeListByDivisionCSV'
                fileExtension = '.csv'
                const employeesByDivisionReportCSVQuery = `
                    {
                        getEmployeesByDivisionForReport {
                                FirstName_VC
                                LastName_VC
                                JobTitle_VC
                                Division_ID
                                Division_VC
                                CellPhone_VC
                                HomePhone_VC
                                OfficePhone_VC
                                OfficeExtension_VC
                                MailingAddress_VC
                                MailingCity_VC
                                MailingState_VC
                                MailingZip_VC
                                Email_VC
                            }
                        }
                    `;

                const employeesByDivisionReportCSVFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: employeesByDivisionReportCSVQuery }), headers: { 'Content-Type': 'application/json' } });
                const employeesByDivisionReportCSVJson = await employeesByDivisionReportCSVFetch.json();

                data = JSON.stringify({ "employees": employeesByDivisionReportCSVJson.data.getEmployeesByDivisionForReport })
                break;
            default:
                break;
        }

        const reportURL = reportServiceURL + endpoint;

        await fetch(reportURL, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        }).then(response => {
            setDownloadingPdf(false);
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${selReportType}${fileExtension}`;
                alink.click();
            })
        })
    }

    const handleTakePhoto = (e) => {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setNewImageB64(true);
                setSelEmployeeInformation(prevState => {
                    return { ...prevState, photo: reader.result }
                });
            };
            setPendingChanges(true)
        }
    };

    const handleRemovePhoto = () => {
        setSelEmployeeInformation(prevState => {
            return {
                ...prevState,
                photo: ""
            }
        });
        setRemovePhoto(true)
        setPendingChanges(true)
    };

    async function getEmployees() {
        setLoadingEmployees(true)
        const filterForDivision = filters.division
        const filterForActiveStatus = filters.radioStatus

        // Employees
        const getEmployeesQuery = `
        {
            getPeopleForCardListFiltered(
                Division_ID: "${filterForDivision}"
                Active_BT: "${filterForActiveStatus}"
            ) {
                People_ID
                FirstName_VC
                LastName_VC
                PhysicalCity_VC
                PhysicalState_VC
                EmployeeType_VC
                Active_BT
                Division_ID
                Division_VC
                JobTitle_VC
            }
        }
        `;
        const getEmployeesFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getEmployeesQuery }), headers: { 'Content-Type': 'application/json' } });
        const getEmployeesJson = await getEmployeesFetch.json();
        const employees = getEmployeesJson.data.getPeopleForCardListFiltered

        employees.forEach((employee) => {
            if (employee.Active_BT === "1" || employee.Active_BT === 1 || employee.Active_BT === true || employee.Active_BT === "true") {
                employee.status = "1"
                employee.statusString = "Active"
            } else if (employee.Active_BT === "0" || employee.Active_BT === 0 || employee.Active_BT === false || employee.Active_BT === "false") {
                employee.status = "0"
                employee.statusString = "Inactive"
            }

            let city = ''
            let state = ''
            if (employee.PhysicalCity_VC && employee.PhysicalCity_VC !== "" && employee.PhysicalCity_VC !== undefined && employee.PhysicalCity_VC !== null) {
                city = employee.PhysicalCity_VC
            }
            if (employee.PhysicalState_VC && employee.PhysicalState_VC !== "" && employee.PhysicalState_VC !== undefined && employee.PhysicalState_VC !== null) {
                state = employee.PhysicalState_VC
            }
            if (city !== '' && state !== '') {
                employee.physicalLocation = `${city}, ${state}`
            } else if (city !== '' && state === '') {
                employee.physicalLocation = city
            } else if (city === '' && state !== '') {
                employee.physicalLocation = state
            } else {
                employee.physicalLocation = ''
            }

        })
        const sortedFirst = employees.sort((a, b) => {
            if (a.FirstName_VC < b.FirstName_VC) {
                return -1;
            } else if (a.FirstName_VC > b.FirstName_VC) {
                return 1;
            } else {
                return 0
            }
        });
        const sortedData = sortedFirst.sort((a, b) => {
            if (a.LastName_VC < b.LastName_VC) {
                return -1;
            } else if (a.LastName_VC > b.LastName_VC) {
                return 1;
            } else {
                return 0
            }
        });

        setFilteredEmployees(sortedData)
        setFilterCombine(sortedData)
        setEmployeeList(sortedData);
        setLoadingEmployees(false)
        handleReapplySearchString(sortedData)
    }

    async function getLists() {
        getEmployees()

        // Employee Types
        const getEmployeeTypesQuery = `
        {
            getEmployeeTypes {
              EmployeeType_ID
              EmployeeType_VC
            }
        }
        `;
        const getEmployeeTypesFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getEmployeeTypesQuery }), headers: { 'Content-Type': 'application/json' } });
        const getEmployeeTypesJson = await getEmployeeTypesFetch.json();
        setEmployeeTypeList(getEmployeeTypesJson.data.getEmployeeTypes);

        // Divisions
        const getDivisionsQuery = `
        {
            getDivisions {
              Division_ID
              Division_VC
            }
        }
        `;
        const getDivisionsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getDivisionsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getDivisionsJson = await getDivisionsFetch.json();

        setDivisionList(getDivisionsJson.data.getDivisions);
    }

    // handle preview pdf
    const handlePreviewReport = async () => {
        setLoadingPdfPreview(true)
        let endpoint = '';
        let data = '';
        switch (selReportType) {
            case "All Active Employees Report (PDF)":
                endpoint = '/employeeListAtoZ'
                const activeEmployeesReportQuery = `
                    {
                        getActiveEmployeesForReport {
                                FirstName_VC
                                LastName_VC
                                JobTitle_VC
                                Division_ID
                                Division_VC
                                CellPhone_VC
                                HomePhone_VC
                                OfficePhone_VC
                                OfficeExtension_VC
                                MailingAddress_VC
                                MailingCity_VC
                                MailingState_VC
                                MailingZip_VC
                                Email_VC
                            }
                        }
                    `;

                const activeEmployeesReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: activeEmployeesReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const activeEmployeesReportJson = await activeEmployeesReportFetch.json();


                data = JSON.stringify({ "employees": activeEmployeesReportJson.data.getActiveEmployeesForReport })
                break;
            case "Filtered Employees List (PDF)":
                endpoint = '/employeeListAtoZ'
                let peopleIDList = []
                filteredEmployees.forEach((person) => {
                    peopleIDList.push(person.People_ID)
                })
                const filteredEmployeesReportQuery = `
                    {
                        getFilteredEmployeesForReport(
                            PeopleIDList: ${JSON.stringify(peopleIDList)}
                        ) {
                                FirstName_VC
                                LastName_VC
                                JobTitle_VC
                                Division_ID
                                Division_VC
                                CellPhone_VC
                                HomePhone_VC
                                OfficePhone_VC
                                OfficeExtension_VC
                                MailingAddress_VC
                                MailingCity_VC
                                MailingState_VC
                                MailingZip_VC
                                Email_VC
                            }
                        }
                    `;

                const filteredEmployeesReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: filteredEmployeesReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const filteredEmployeesReportJson = await filteredEmployeesReportFetch.json();


                data = JSON.stringify({ "employees": filteredEmployeesReportJson.data.getFilteredEmployeesForReport })
                break;
            case "Employees Grouped By Division (PDF)":
                endpoint = '/employeeListByDivision'
                const employeesByDivisionReportQuery = `
                {
                    getEmployeesByDivisionForReport {
                            FirstName_VC
                            LastName_VC
                            JobTitle_VC
                            Division_ID
                            Division_VC
                            CellPhone_VC
                            HomePhone_VC
                            OfficePhone_VC
                            OfficeExtension_VC
                            MailingAddress_VC
                            MailingCity_VC
                            MailingState_VC
                            MailingZip_VC
                            Email_VC
                        }
                    }
                `;

                const employeesByDivisionReportFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: employeesByDivisionReportQuery }), headers: { 'Content-Type': 'application/json' } });
                const employeesByDivisionReportJson = await employeesByDivisionReportFetch.json();


                data = JSON.stringify({ "employees": employeesByDivisionReportJson.data.getEmployeesByDivisionForReport, "divisions": divisionList })
                break;
            default:
                break;
        }

        const reportURL = reportServiceURL + endpoint;

        await fetch(reportURL, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: data
        }).then(response => {
            setLoadingPdfPreview(false);
            response.blob().then(blob => {
                // Creating new object of PDF file
                setPdf(window.URL.createObjectURL(blob))
            })
        })
    }


    const handleSave = async () => {
        setLoadingEmployeeSave(true)
        if (pendingChanges && creatingNewEmployee) {
            // Handle Save New Employee

            const addPersonQuery = `
            {
                addPerson(
                    FirstName_VC: ${JSON.stringify(selEmployeeInformation.firstName)}
                    LastName_VC: ${JSON.stringify(selEmployeeInformation.lastName)}
                    PhysicalAddress_VC: ${JSON.stringify(selEmployeeInformation.physicalStreet)}
                    PhysicalCity_VC: ${JSON.stringify(selEmployeeInformation.physicalCity)}
                    PhysicalState_VC: ${JSON.stringify(selEmployeeInformation.physicalState)}
                    PhysicalZip_VC: ${JSON.stringify(selEmployeeInformation.physicalZip)}
                    MailingAddress_VC: ${JSON.stringify(selEmployeeInformation.mailingStreet)}
                    MailingCity_VC: ${JSON.stringify(selEmployeeInformation.mailingCity)}
                    MailingState_VC: ${JSON.stringify(selEmployeeInformation.mailingState)}
                    MailingZip_VC: ${JSON.stringify(selEmployeeInformation.mailingZip)}
                    CellPhone_VC: ${JSON.stringify(selEmployeeInformation.cellPhone)}
                    HomePhone_VC: ${JSON.stringify(selEmployeeInformation.homePhone)}
                    OfficePhone_VC: ${JSON.stringify(selEmployeeInformation.officePhone)}
                    OfficeExtension_VC: ${JSON.stringify(selEmployeeInformation.extension)}
                    EmployeeType_ID: ${JSON.stringify(selEmployeeInformation.employeeType)}
                    Active_BT: ${JSON.stringify(selEmployeeInformation.status)}
                    AgronomistNumber_IN: ${JSON.stringify(selEmployeeInformation.agronomistNumber)}
                    Email_VC: ${JSON.stringify(selEmployeeInformation.email)}
                    Division_ID: ${JSON.stringify(selEmployeeInformation.division)}
                    JobTitle_VC: ${JSON.stringify(selEmployeeInformation.title)}
                    Image_B64: "${selEmployeeInformation.photo}"

                )
            }
`

            // IsManager_BT : ${JSON.stringify(selEmployeeInformation.isManager))}
            const addPersonFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: addPersonQuery }), headers: { 'Content-Type': 'application/json' } });
            // const addPersonJson = 
            await addPersonFetch.json();

            handleSetNewEmployeeMode(false)
            setPendingChanges(false)
        } else if (pendingChanges && !creatingNewEmployee) {
            // Handle Save Edits

            const updatePersonQuery = `
            {
                updatePerson(
                    People_ID: ${JSON.stringify(selEmployeeInformation.personID)}
                    FirstName_VC: ${JSON.stringify(selEmployeeInformation.firstName)}
                    LastName_VC: ${JSON.stringify(selEmployeeInformation.lastName)}
                    PhysicalAddress_VC: ${JSON.stringify(selEmployeeInformation.physicalStreet)}
                    PhysicalCity_VC: ${JSON.stringify(selEmployeeInformation.physicalCity)}
                    PhysicalState_VC: ${JSON.stringify(selEmployeeInformation.physicalState)}
                    PhysicalZip_VC: ${JSON.stringify(selEmployeeInformation.physicalZip)}
                    MailingAddress_VC: ${JSON.stringify(selEmployeeInformation.mailingStreet)}
                    MailingCity_VC: ${JSON.stringify(selEmployeeInformation.mailingCity)}
                    MailingState_VC: ${JSON.stringify(selEmployeeInformation.mailingState)}
                    MailingZip_VC: ${JSON.stringify(selEmployeeInformation.mailingZip)}
                    CellPhone_VC: ${JSON.stringify(selEmployeeInformation.cellPhone)}
                    HomePhone_VC: ${JSON.stringify(selEmployeeInformation.homePhone)}
                    OfficePhone_VC: ${JSON.stringify(selEmployeeInformation.officePhone)}
                    OfficeExtension_VC: ${JSON.stringify(selEmployeeInformation.extension)}
                    EmployeeType_ID: ${JSON.stringify(selEmployeeInformation.employeeType)}
                    Active_BT: ${JSON.stringify(selEmployeeInformation.status)}
                    AgronomistNumber_IN: ${JSON.stringify(selEmployeeInformation.agronomistNumber)}
                    Email_VC: ${JSON.stringify(selEmployeeInformation.email)}
                    Division_ID: ${JSON.stringify(selEmployeeInformation.division)}
                    JobTitle_VC: ${JSON.stringify(selEmployeeInformation.title)}
                )
            }
`

            // IsManager_BT : ${JSON.stringify(selEmployeeInformation.isManager))}
            const updatePersonFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: updatePersonQuery }), headers: { 'Content-Type': 'application/json' } });
            // const updatePersonJson = 
            await updatePersonFetch.json();

            if (newImageB64 && selEmployeeInformation.photoID !== "") {

                // If we have new image, and assetImageID is not empty, we have an existing image entry, need to update
                let updateImageQuery = `
                    {
                        updatePersonImage(
                            People_ID: "${selEmployeeInformation.personID}"
                            PeopleImage_ID: "${selEmployeeInformation.photoID}"
                            Image_B64: "${selEmployeeInformation.photo}"
                            FirstName_VC: "${selEmployeeInformation.firstName}"
                            LastName_VC: "${selEmployeeInformation.lastName}"
                        )
                    }
                `;

                const updateImageFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: updateImageQuery }), headers: { 'Content-Type': 'application/json' } });
                // const updateImageJson = 
                await updateImageFetch.json();

            } else if (newImageB64 && selEmployeeInformation.photoID === "") {
                // If we have a new image and we don't have an existing assetImageID, no existing image for asset, add entry
                let addImageQuery = `
                {
                    addPersonImage(
                        Image_B64: "${selEmployeeInformation.photo}"
                        People_ID: "${selEmployeeInformation.personID}"
                        FirstName_VC: "${selEmployeeInformation.firstName}"
                        LastName_VC: "${selEmployeeInformation.lastName}"
                        Active_BT: "1"
                        Primary_BT: "1"
                    )
                }
            `;

                const addImageFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: addImageQuery }), headers: { 'Content-Type': 'application/json' } });
                const addImageJson = await addImageFetch.json();
                const imageID = addImageJson.data.addPersonImage

                setSelEmployeeInformation(prevState => {
                    return {
                        ...prevState,
                        photoID: imageID
                    }
                })
            }

            if (removePhoto) {
                let deactivateImageQuery = `
                {
                updatePersonImage(
                        PeopleImage_ID: "${selEmployeeInformation.photoID}"
                        Active_BT: "0"
                    )
                }
                `;

                const deactivateImageFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: deactivateImageQuery }), headers: { 'Content-Type': 'application/json' } });
                // const deactivateImageJson = 
                await deactivateImageFetch.json();

                setSelEmployeeInformation(prevState => {
                    return {
                        ...prevState,
                        photoID: ''
                    }
                })
            }

            setPendingChanges(false)
        } else {
            // No pending changes. Don't save anything
        }
        // Close save changes modal if save is clicked from modal (will have no effect if modal isn't open)
        handleClose()
        setLoadingEmployeeSave(false)
        getEmployees()
        setRemovePhoto(false)
        setEmployeeExists(false)

    }

    const handleCancel = () => {
        if (pendingChanges && creatingNewEmployee) {
            // Handle Cancel  New Employee
            setSelEmployeeInformation(selEmployeeInfoUnedited)
            handleSetNewEmployeeMode(false)
            setPendingChanges(false);
        } else if (pendingChanges && !creatingNewEmployee) {
            // Handle Cancel Edits
            setSelEmployeeInformation(selEmployeeInfoUnedited)
            setPendingChanges(false)
        } else if (!pendingChanges && creatingNewEmployee) {
            // Started creating new employee, but didn't add any information
            setSelEmployeeInformation(selEmployeeInfoUnedited)
            handleSetNewEmployeeMode(false)
        } else {
            // No pending changes
        }
        // Close save changes modal if cancel is clicked (will have no effect if modal isn't open)
        setRemovePhoto(false)
        setNewImageB64(false)
        handleClose()
    }

    useEffect(() => {
        sortEmployees()
        // eslint-disable-next-line 
    }, [filteredEmployees])

    useEffect(async () => {
        if (creatingNewEmployee && selEmployeeInformation.email.includes(".com")){
            // Check to see if email already exists
            const existingEmployee = await handleCheckExistingEmployee(selEmployeeInformation.email)
            setEmployeeExists(existingEmployee)
        } else {
            setEmployeeExists(false)
        }
    }, [selEmployeeInformation.email])

    useEffect(() => {
        // Refreshes selEmployeeInformation from DB after save
        if (loadingEmployeeSave === false && selEmployeeInformation.personID !== "") {
            const personID = selEmployeeInformation.personID
            handleRefreshData(personID)
        }
        // eslint-disable-next-line 
    }, [loadingEmployeeSave])


    useEffect(() => {
        getLists();
        // eslint-disable-next-line 
    }, [])

    return (
        <div className="container-fluid">
            {downloadingPdf &&
                <div width="100%" className="downloading">
                    <Spinner></Spinner>
                </div>
            }
            <div className="row">
                <div className="col-sm-4">
                    <div className="d-flex search-bar">
                        <div className="mx-2 ps-0">
                            {!pendingChanges &&
                                <div data-bs-toggle="modal" data-bs-target="#filter-modal">
                                    <i className="bi bi-filter-circle" onClick={() => setFiltersUnedited(filters)} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                </div>
                            }
                            {pendingChanges &&
                                <div>
                                    <i className="bi bi-filter-circle" onClick={() => handleShow()} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                </div>
                            }
                        </div>

                        <div className="input-group mb-2">
                            {!pendingChanges &&
                                <input type="text" className="form-control ST-form mx-0" id="filter-search-bar" style={{ "textJustify": "right" }} onKeyUp={handleSearchKeyUp} placeholder="Start typing to search..." value={filters.searchString} onChange={(e) => handleUpdateFiltersReports(e)} />
                            }
                            {pendingChanges &&
                                <input type="text" className="form-control ST-form mx-0" id="filter-search-bar" style={{ "textJustify": "right" }} disabled placeholder={filters.searchString} />
                            }

                        </div>
                        <div className="mx-2 ps-0">
                            <div data-bs-toggle="modal" data-bs-target="#mobile-view-modal" className="mobile-card">
                                <i className="bi bi-plus-square" data-bs-toggle="tooltip" title="Add New Employee" onClick={() => handleSetNewEmployeeMode(true)} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                            </div>
                            <div className="desktop-card">
                                <i className="bi bi-plus-square" data-bs-toggle="tooltip" title="Add New Employee" onClick={() => handleSetNewEmployeeMode(true)} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                            </div>
                        </div>
                        {/* <div className="mx-2 ps-0">
                                <a>
                                    <i className="bi bi-pencil-square" data-bs-toggle="tooltip" title="Edit Employee" onClick={() => handleSetEditingMode(true)} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                </a>
                            </div> */}
                        <div className="mx-2 ps-0">
                            {pendingChanges &&
                                <div>
                                    <i className="bi bi-file-earmark-arrow-up" data-bs-toggle="tooltip" title="Export Reports" onClick={() => handleShow()} style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                </div>
                            }
                            {!pendingChanges &&
                                <div data-bs-toggle="modal" data-bs-target="#report-modal">
                                    <i className="bi bi-file-earmark-arrow-up" data-bs-toggle="tooltip" title="Export Reports" style={{ "fontSize": "1.5rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                </div>
                            }
                        </div>

                    </div>

                    <div className="modal fade" id="filter-modal" aria-hidden="true" aria-labelledby="Set Filters" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Set Filters</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mt-0">
                                        <label htmlFor="filterDivision" className="form-label service-form-label">Filter by Division</label>
                                        <select className="form-select" value={filters.division} id="filterDivision" onChange={(e) => handleUpdateFiltersReports(e)}>
                                            <option value="">All</option>
                                            {divisionList.map((division) =>
                                                <option key={division.Division_ID} value={division.Division_ID}>{division.Division_ID} - {division.Division_VC}</option>
                                            )}
                                        </select>
                                    </div>
                                    <hr className="mt-4" />
                                    <div className="mt-2">
                                        <label htmlFor="labelActiveStatus" className="form-label service-form-label pe-2">Filter by Active Status:</label>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioFilterStatus" id="radioFilterStatus" value="" onChange={(e) => handleFilterRadio(e)} checked={filters.radioStatus === ""} />
                                            <label className="form-check-label service-check-label"
                                                htmlFor="radioFilterStatus">All</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioFilterStatus" id="radioFilterStatus" value="1" onChange={(e) => handleFilterRadio(e)} checked={filters.radioStatus === "1"} />
                                            <label className="form-check-label service-check-label"
                                                htmlFor="radioFilterStatus">Active</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadiFilterStatus" id="radioFilterStatus" value="0" onChange={(e) => handleFilterRadio(e)} checked={filters.radioStatus === "0"} />
                                            <label className="form-check-label service-check-label"
                                                htmlFor="radioFilterStatus">Inactive</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-6">
                                                <button type="button" className="button-max-secondary" data-bs-dismiss="modal" onClick={() => setFilters(filtersUnedited)}>Cancel</button>
                                            </div>
                                            <div className="col-6">
                                                <button type="button" className="button-max-primary" data-bs-dismiss="modal" onClick={() => handleApplyFilters()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="report-modal" aria-hidden="true" aria-labelledby="Choose Report" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Choose Report</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mt-0">
                                        <select className="form-select" id='report-type' value={selReportType} onChange={(e) => { handleUpdateFiltersReports(e) }}>
                                            <option value="" disabled>-- choose a report type --</option>
                                            {reportTypes.map((report, index) =>
                                                <option value={report} key={index}>{report}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="button-max-secondary" data-bs-dismiss="modal" onClick={() => setSelReportType("")}>Cancel</button>
                                            </div>
                                            {selReportType && selReportType !== "" &&
                                                <>
                                                    {selReportType.includes("CSV") &&
                                                        <div className="col-4">
                                                            <button type="button" className="button-max-primary" disabled >View</button>
                                                        </div>
                                                    }
                                                    {!selReportType.includes("CSV") &&
                                                        <div className="col-4">
                                                            <button type="button" className="button-max-primary" data-bs-toggle="modal" data-bs-target="#modalPrintPreview" onClick={() => handlePreviewReport()}>View</button>
                                                        </div>
                                                    }
                                                    <div className="col-4">
                                                        <button type="button" className="button-max-primary" data-bs-dismiss="modal" onClick={() => handleDownloadReport()}>Download</button>
                                                    </div>
                                                </>
                                            }
                                            {(!selReportType || selReportType === "") &&
                                                <>
                                                    <div className="col-4">
                                                        <button type="button" className="button-max-primary" disabled>View</button>
                                                    </div>
                                                    <div className="col-4">
                                                        <button type="button" className="button-max-primary" data-bs-dismiss="modal" disabled>Download</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="uploadNewLogo" aria-hidden="true"
                        aria-labelledby="Upload Pictures Modal" tabIndex="-1" >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Upload Pictures</h1>
                                    <button type="button" className="btn-close" data-bs-target="#addAddressToggle"
                                        data-bs-toggle="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    This is a value dialog modal for the filesystem one.
                                </div>
                                <div className="modal-footer">
                                    <div className="container">
                                        <div className="row">
                                            {/* Mobile Buttons */}
                                            <div className="col-6 mobile-card">
                                                <button type="button" className="button-max-secondary"
                                                    data-bs-target="#mobile-view-modal" data-bs-toggle="modal">Cancel</button>
                                            </div>
                                            <div className="col-6 mobile-card">
                                                <button type="button" className="button-max-primary"
                                                    data-bs-target="#mobile-view-modal" data-bs-toggle="modal">Save</button>
                                            </div>
                                            {/* Desktop Buttons */}
                                            <div className="col-6 desktop-card">
                                                <button type="button" className="button-max-secondary" data-bs-dismiss="modal">Cancel</button>
                                            </div>
                                            <div className="col-6 desktop-card">
                                                <button type="button" className="button-max-primary" data-bs-dismiss="modal">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="mobile-view-modal" aria-hidden="true" aria-labelledby="Mobile View" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ "position": "right" }} onClick={() => handleCancel()}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mt-0">
                                        {selEmployeeInformation &&
                                            < EditEmployeeView
                                                selEmployeeInformation={selEmployeeInformation}
                                                divisionList={divisionList}
                                                stateList={stateList}
                                                employeeTypeList={employeeTypeList}
                                                creatingNewEmployee={creatingNewEmployee}
                                                pendingChanges={pendingChanges}
                                                handleEmployeeRadioInfo={handleEmployeeRadioInfo}
                                                handleUpdateSelEmployeeInfo={handleUpdateSelEmployeeInfo}
                                                handleCopyToAddress={handleCopyToAddress}
                                                handleCancel={handleCancel}
                                                handleSave={handleSave}
                                                mobile={true}
                                                loadingCard={loadingCard}
                                                fileInput={fileInput}
                                                handleTakePhoto={handleTakePhoto}
                                                handleRemovePhoto={handleRemovePhoto}
                                                newImageB64={newImageB64}
                                                loadingEmployeeSave={loadingEmployeeSave}
                                                employeeExists={employeeExists}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal" id="modalPrintPreview">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <h4 className="modal-title">View Report</h4>
                                    <button type="button" className="btn-close" data-bs-target="#report-modal"
                                        data-bs-toggle="modal"></button>
                                </div>

                                <div className="modal-body">
                                    <div width="100%" className="pdfPreview">
                                        {loadingPdfPreview &&
                                            <Spinner className="spinner"></Spinner>
                                        }
                                        {!loadingPdfPreview &&
                                            <embed src={pdf} type='application/pdf' width="100%" height="550"></embed>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={show} id="save-changes-modal" aria-hidden="true" aria-labelledby="Save Changes?" tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered save-changes">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Save Changes?</h1>
                                    <button type="button" className="btn-close" onClick={() => handleClose()}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mt-0">
                                        <span>You have changes pending.</span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="container">
                                        <div className="row">
                                            {/* Mobile Buttons */}
                                            <div className="col-6 mobile-card">
                                                <button type="button" className="button-max-secondary"
                                                    data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleCancel()}>Cancel</button>
                                            </div>
                                            <div className="col-6 mobile-card">
                                                <button type="button" className="button-max-primary"
                                                    data-bs-target="#mobile-view-modal" data-bs-toggle="modal" onClick={() => handleSave()}>Save</button>
                                            </div>
                                            {/* Desktop Buttons */}
                                            <div className="col-6 desktop-card">
                                                <button type="button" className="button-max-secondary" data-bs-dismiss="modal" onClick={() => handleCancel()}>Cancel</button>
                                            </div>
                                            <div className="col-6 desktop-card">
                                                <button type="button" className="button-max-primary" data-bs-dismiss="modal" onClick={() => handleSave()}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <div className="list-group-scroll-reports">
                        <ul className="list-group" id="listPeople" style={{ "marginBlockEnd": "200px" }}>
                            {!loadingEmployees && filteredEmployees && filteredEmployees.length !== 0 && filteredEmployees.map((employee) =>
                                <Card className={(selCardIndex === employee.People_ID) ? "emp-card list-group-item item-hilight active" : "emp-card list-group-item item-hilight"} key={employee.People_ID}
                                    onClick={() => handleUpdateSelectedEmployee(employee.People_ID)} >
                                    <div className="desktop-card">
                                        <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                            <div>
                                                <span className="employee-name">{`${employee.FirstName_VC} ${employee.LastName_VC}`}</span>
                                            </div>
                                            <div>
                                                <span className="employee-label">{employee.statusString}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                            <div>
                                                <span className="employee-text">{employee.JobTitle_VC}</span>
                                            </div>
                                            <div>
                                                <span className="employee-text">{employee.Division_ID} - {employee.Division_VC}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                            <div>
                                                <span className="employee-text">{employee.physicalLocation}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobile-card" data-bs-toggle="modal" data-bs-target="#mobile-view-modal" >
                                        <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                            <div>
                                                <span className="employee-name">{`${employee.FirstName_VC} ${employee.LastName_VC}`}</span>
                                            </div>
                                            <div>
                                                <span className="employee-label">{employee.statusString}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                            <div>
                                                <span className="employee-text">{employee.JobTitle_VC}</span>
                                            </div>
                                            <div>
                                                <span className="employee-text">{employee.Division_ID} - {employee.Division_VC}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap justify-content-between lh-sm">
                                            <div>
                                                <span className="employee-text">{employee.physicalLocation}</span>
                                            </div>
                                        </div>
                                    </div>

                                </Card>
                            )}
                            {!loadingEmployees && (!filteredEmployees || filteredEmployees.length === 0) &&
                                <div>No employees found with current filters</div>
                            }
                            {loadingEmployees &&
                                <div className='spinner'><Spinner /></div>
                            }

                        </ul>
                    </div>
                </div>

                <div className="col-sm-8 d-none d-sm-block p-1">
                    <div width="100%" style={{ "height": "100%" }}>
                        {selEmployeeInformation && (selEmployeeInformation.personID || creatingNewEmployee) &&
                            < EditEmployeeView
                                selEmployeeInformation={selEmployeeInformation}
                                divisionList={divisionList}
                                stateList={stateList}
                                employeeTypeList={employeeTypeList}
                                creatingNewEmployee={creatingNewEmployee}
                                pendingChanges={pendingChanges}
                                handleEmployeeRadioInfo={handleEmployeeRadioInfo}
                                handleUpdateSelEmployeeInfo={handleUpdateSelEmployeeInfo}
                                handleCopyToAddress={handleCopyToAddress}
                                handleCancel={handleCancel}
                                handleSave={handleSave}
                                mobile={false}
                                loadingCard={loadingCard}
                                fileInput={fileInput}
                                handleTakePhoto={handleTakePhoto}
                                handleRemovePhoto={handleRemovePhoto}
                                newImageB64={newImageB64}
                                loadingEmployeeSave={loadingEmployeeSave}
                                employeeExists={employeeExists}
                            />
                        }
                    </div>
                </div>
            </div>

        </div >
    )
}

export default EmployeeManagerInterface;