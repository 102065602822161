import '../../../styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Spinner } from 'react-bootstrap';
import EmployeePlaceholder from '../../../svg/EmployeePlaceholder.svg'


const EditEmployeeView = (props) => {
    const {
        selEmployeeInformation,
        divisionList,
        stateList,
        employeeTypeList,
        creatingNewEmployee,
        pendingChanges,
        handleEmployeeRadioInfo,
        handleUpdateSelEmployeeInfo,
        handleCopyToAddress,
        handleCancel,
        handleSave,
        mobile,
        loadingCard,
        handleTakePhoto,
        handleRemovePhoto,
        newImageB64,
        fileInput,
        loadingEmployeeSave,
        employeeExists
    } = props;


    return (
        <div className="container-fluid px-2" style={{ "marginTop": "10px" }}>
            <div className="card mx-auto">
                <div className="card-body">
                    {loadingCard &&
                        <div className="spinner-mobile "><Spinner /></div>
                    }
                    {!loadingCard &&
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="service-subheading mt-2 mb-2">
                                    Employee Information
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefFirstName" className="form-label service-form-label">First Name</label>
                                    <input type="text" className="form-control" id="prefFirstName"
                                        value={selEmployeeInformation.firstName} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefLastName" className="form-label service-form-label">Last Name</label>
                                    <input type="text" className="form-control" id="prefLastName" value={selEmployeeInformation.lastName} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefTitle" className="form-label service-form-label">Title</label>
                                    <input type="text" className="form-control" id="prefTitle" value={selEmployeeInformation.title} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />

                                </div>
                                <div className="mb-1">
                                    <label htmlFor="prefDivision" className="form-label service-form-label" >Division or
                                        Territory</label>
                                    <select className="form-select" id="prefDivision" value={selEmployeeInformation.division} onChange={(e) => handleUpdateSelEmployeeInfo(e)}>
                                        <option value="" disabled>-- select an option --</option>
                                        {divisionList.map((division) =>
                                            <option value={division.Division_ID} key={division.Division_ID}>{division.Division_ID} - {division.Division_VC}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefEmail" className="form-label service-form-label">Email Address</label>
                                    <input type="text" className="form-control" id="prefEmail"
                                        placeholder="" value={selEmployeeInformation.email} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                {creatingNewEmployee && employeeExists &&
                                    <div className="email-warning">
                                        An employee with this email address already exists!
                                    </div>
                                }
                                <div className="mt-1">
                                    <label htmlFor="prefAgroNumber" className="form-label service-form-label">Agronomist Number</label>
                                    <input type="text" className="form-control" id="prefAgroNumber"
                                        placeholder="" value={selEmployeeInformation.agronomistNumber} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="prefCellPhone" className="form-label service-form-label">Cell Phone</label>
                                    <input type="phone" className="form-control" id="prefCellPhone"
                                        value={selEmployeeInformation.cellPhone} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="prefHomePhone" className="form-label service-form-label">Home Phone</label>
                                    <input type="phone" className="form-control" id="prefHomePhone"
                                        value={selEmployeeInformation.homePhone} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="prefOfficePhone" className="form-label service-form-label">Office Phone</label>
                                    <input type="phone" className="form-control" id="prefOfficePhone"
                                        value={selEmployeeInformation.officePhone} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="prefExtension" className="form-label service-form-label">Extension</label>
                                    <input type="phone" className="form-control" id="prefExtension"
                                        value={selEmployeeInformation.extension} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefEmpType" className="form-label service-form-label">Employee Type</label>
                                    <select className="form-select" id="prefEmpType" value={selEmployeeInformation.employeeType} onChange={(e) => handleUpdateSelEmployeeInfo(e)}>
                                        <option value='' disabled>-- select an employee type --</option>
                                        {employeeTypeList.map((employeeType) =>
                                            <option value={employeeType.EmployeeType_ID} key={employeeType.EmployeeType_ID}>{employeeType.EmployeeType_VC}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="labelStatus" className="form-label service-form-label pe-2">Status:</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name={mobile ? "inlineRadioStatusMobile" : "inlineRadioStatus"} id="radioStatusEmp" value="1" onChange={(e) => handleEmployeeRadioInfo(e)} checked={selEmployeeInformation.status === "1"} />
                                        <label className="form-check-label service-check-label"
                                            htmlFor="radioStatusEmp">Active</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name={mobile ? "inlineRadioStatusMobile" : "inlineRadioStatus"} id="radioStatusEmp" value="0" onChange={(e) => handleEmployeeRadioInfo(e)} checked={selEmployeeInformation.status === "0"} />
                                        <label className="form-check-label service-check-label"
                                            htmlFor="radioStatusEmp">Inactive</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="service-subheading mt-2 mb-2">
                                    Physical Address
                                    <span className="mx-2 ps-0">
                                        <i className="bi bi-house-down" data-bs-toggle="tooltip" title="Copy to Mailing Address" onClick={() => handleCopyToAddress("toMailing")} style={{ "fontSize": "1.25rem", "color": "#007aff", "cursor": "pointer" }}></i>
                                    </span>
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefPhyStreet" className="form-label service-form-label">Address</label>
                                    <input type="text" className="form-control" id="prefPhyStreet"
                                        value={selEmployeeInformation.physicalStreet} onChange={(e) => handleUpdateSelEmployeeInfo(e)}></input>
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefPhyCity" className="form-label service-form-label">City</label>
                                    <input type="text" className="form-control" id="prefPhyCity"
                                        value={selEmployeeInformation.physicalCity} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="mb-1">
                                            <label htmlFor="prefPhyState" className="form-label service-form-label">State</label>
                                            <select className="form-select" id="prefPhyState" value={selEmployeeInformation.physicalState} onChange={(e) => handleUpdateSelEmployeeInfo(e)}>
                                                <option value="" disabled></option>
                                                {stateList.map((state, index) =>
                                                    <option value={state} key={index}>{state}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="mt-1">
                                            <label htmlFor="prefPhyZip" className="form-label service-form-label">Zip Code</label>
                                            <input type="text" className="form-control" id="prefPhyZip"
                                                value={selEmployeeInformation.physicalZip} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="service-subheading mt-4 mb-2">
                                    Mailing Address
                                    <span className="mx-2 ps-0">

                                        <i className="bi bi-house-up" data-bs-toggle="tooltip" title="Copy to Physical Address" onClick={() => handleCopyToAddress("toPhysical")} style={{ "fontSize": "1.25rem", "color": "#007aff", "cursor": "pointer" }}></i>

                                    </span>
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefMailStreet" className="form-label service-form-label">Address</label>
                                    <input type="text" className="form-control" id="prefMailStreet"
                                        value={selEmployeeInformation.mailingStreet} onChange={(e) => handleUpdateSelEmployeeInfo(e)}></input>
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="prefMailCity" className="form-label service-form-label">City</label>
                                    <input type="text" className="form-control" id="prefMailCity"
                                        value={selEmployeeInformation.mailingCity} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                </div>
                                <div className="row mt-1">
                                    <div className="col-sm-4">
                                        <div className="mb-1">
                                            <label htmlFor="prefMailState" className="form-label service-form-label">State</label>
                                            <select className="form-select" id="prefMailState" value={selEmployeeInformation.mailingState} onChange={(e) => handleUpdateSelEmployeeInfo(e)} >
                                                <option value="" disabled></option>
                                                {stateList.map((state, index) =>
                                                    <option value={state} key={index}>{state}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="mt-1">
                                            <label htmlFor="prefMailZip" className="form-label service-form-label">Zip Code</label>
                                            <input type="text" className="form-control" id="prefMailZip"
                                                value={selEmployeeInformation.mailingZip} onChange={(e) => handleUpdateSelEmployeeInfo(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-1">
                                    <div className="service-subheading mt-4">Upload Photo</div>
                                    <div className="row g-0 mt-2 justify-content-evenly">
                                        <div className="col-6">
                                            <label htmlFor="capture-button">
                                                {!mobile && (newImageB64 || selEmployeeInformation.photo !== "") &&
                                                    <div className="button-pill-small" >
                                                        Change Photo
                                                    </div>
                                                }
                                                {!mobile && (!newImageB64 || newImageB64 === undefined || newImageB64 === null) && selEmployeeInformation.photo === "" &&
                                                    <div className="button-pill-small" >
                                                        Choose Photo
                                                    </div>
                                                }
                                                {mobile && (newImageB64 || selEmployeeInformation.photo !== "") &&
                                                    <div className="button-pill-small" >
                                                        Retake Photo
                                                    </div>
                                                }
                                                {mobile && (!newImageB64 || newImageB64 === undefined || newImageB64 === null) && selEmployeeInformation.photo === "" &&
                                                    <div className="button-pill-small" >
                                                        Take Photo
                                                    </div>
                                                }


                                                <input
                                                    accept="image/*,image/heif"
                                                    capture
                                                    hidden
                                                    id="capture-button"
                                                    // key={fileInputKey}
                                                    ref={fileInput}
                                                    onChange={e => handleTakePhoto(e)}
                                                    type="file"
                                                />
                                            </label>
                                            <div><button className="button-pill-small-caution" onClick={() => handleRemovePhoto()}>Remove Photo</button></div>

                                        </div>
                                        <div className="col-6">
                                            <div><img alt='' src={selEmployeeInformation.photo || EmployeePlaceholder} width="125px" /></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="pt-4 mt-4">
                                        <div className="row">
                                            {mobile &&
                                                <>
                                                    <div className="col-6">
                                                        <button type="button" className="button-max-secondary" onClick={() => handleCancel()} data-bs-dismiss="modal">Cancel</button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="button" className={pendingChanges ? "button-max-primary" : "button-max-secondary"} onClick={() => handleSave()} data-bs-dismiss="modal">{loadingEmployeeSave ? <Spinner style={{ "color": "black" }} /> : "Save"}</button>
                                                    </div>
                                                </>
                                            }
                                            {!mobile &&
                                                <>
                                                    <div className="col-6">
                                                        <button type="button" className="button-max-secondary" onClick={() => handleCancel()}>Cancel</button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="button" className={pendingChanges ? "button-max-primary" : "button-max-secondary"} onClick={() => handleSave()}>{loadingEmployeeSave ? <Spinner style={{ "color": "black" }} /> : "Save"}</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditEmployeeView;